import React, { useState, useEffect } from "react";

import { Button, Modal } from "react-bootstrap";

import "react-multi-carousel/lib/styles.css";

import ProjectTabSection3 from "components/hccb/ProjectTabSection3";

import { Link } from "gatsby";
// data
import { hccbplantation } from "assets/data/hccbmap";

import saplingtree from "assets/images/hccb/sapling-tree.png";
import econmicforest from "assets/images/hccb/econmic-forest.png";
import acresicon from "assets/images/hccb/acres-icon.png";
import upcomingicon from "assets/images/hccb/upcoming-icon.png";
import eventdate from "assets/images/hccb/event-date.png";
import youtube from "assets/images/hccb/youtube-icon.svg";
import SimpleReactLightbox from "simple-react-lightbox";
import HccbRecentPlantations from "./recentplantation";

import { SRLWrapper } from "simple-react-lightbox";

import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

export default function Wherewhen() {
  const [locationData, setLocationData] = useState([]);
  const [locationDataImage, setLocationDataImage] = useState([]);
  const [mobileLocation, setMobileLocation] = useState(false);
  const [showVid, setShowVid] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 3,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };

  const onSelectLocation = async (name) => {
    const location = hccbplantation.carousel.filter((x) => x.id === name.id);
    setLocationData(location);
    setLocationDataImage("");
    setMobileLocation(isMobile);

    const timer = setTimeout(() => {
      setLocationDataImage(location[0].images);
    }, 1000);
    return () => clearTimeout(timer);
  };
  const closeButton = () => {
    setMobileLocation(false);
  };
  const closeVideo = () => {
    setShowVid(false);
  };

  useEffect(() => {
    window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    const location = hccbplantation.carousel.filter((x) => x.id === 1);
    setLocationData(location);
    setLocationDataImage(location[0].images);
  }, []);

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className="carousel-button-group">
        <div className="arrow-navigations custom-arrow-navigation-event">
          <button className="pr-custom top-hccb" onClick={() => previous()}>
            <i className="icon icon-arrow-left-short"></i>
          </button>
          <button className="pr-rightcustom top-hccb" onClick={() => next()}>
            <i className="icon icon-arrow-right-short"></i>
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="project-section1">
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="">
                <h3 className="hccb-heading">Where and When?</h3>
              </div>
              {!isMobile && (
                <>
                  <h4 className="when-town">
                    {" "}
                    {locationData[0] && locationData[0].plantationsite}{" "}
                  </h4>
                  <h5 className="when-city">
                    {" "}
                    {locationData[0] && locationData[0].state}
                  </h5>

                  <div className="object-where-when-sapling">
                    <div className="where-when-sapling-box">
                      <div className="where-when-sapling-box-img">
                        <img
                          data-src={saplingtree}
                          className="lazyload"
                          alt="saplingtree"
                        />
                      </div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number">
                          {locationData[0] && locationData[0].saplings}
                        </p>
                        <p className="sapling-box-title">Saplings</p>
                      </div>
                    </div>
                    <div className="where-when-sapling-box">
                      <div className="where-when-sapling-box-img">
                        <img
                          data-src={econmicforest}
                          className="lazyload"
                          alt="econmicforest"
                        />
                      </div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number">
                          {locationData[0] && locationData[0].plantationstyle}
                        </p>
                        <p className="sapling-box-title">Plantation Style</p>
                      </div>
                    </div>

                    <div className="where-when-sapling-box">
                      <div className="where-when-sapling-box-img">
                        <img
                          data-src={acresicon}
                          className="lazyload"
                          alt="acresicon"
                        />
                      </div>
                      <div className="where-when-sapling-box-content">
                        <p
                          className="sapling-box-number"
                          dangerouslySetInnerHTML={{
                            __html: locationData[0] && locationData[0].area,
                          }}
                        />
                        {/*<p className="sapling-box-number">
                          {locationData[0] && locationData[0].area}
                        </p>
                        <p className="sapling-box-title">Acres</p>

                        */}
                      </div>
                    </div>
                    <div className="where-when-sapling-box">
                      <div className="where-when-sapling-box-img">
                        <img
                          data-src={upcomingicon}
                          className="lazyload"
                          alt="upcomingicon"
                        />
                      </div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number green">
                          {locationData[0] && locationData[0].projectstatus}
                        </p>
                        <p className="sapling-box-title">Project Status</p>
                      </div>
                    </div>
                    <div className="where-when-sapling-box">
                      <div className="where-when-sapling-box-img">
                        <img
                          data-src={eventdate}
                          className="lazyload"
                          alt="eventdate"
                        />
                      </div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number">
                          {locationData[0] && locationData[0].employeeevent}
                        </p>
                        <p className="sapling-box-title">
                          Plantation Event Date
                        </p>
                      </div>
                    </div>
                    <div className="where-when-sapling-box">
                      <div className="where-when-sapling-box-img">
                        <img
                          data-src={eventdate}
                          className="lazyload"
                          alt="eventdate"
                        />
                      </div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number">
                          {locationData[0] && locationData[0].plantationperiod}
                        </p>
                        <p className="sapling-box-title">Plantation period </p>
                      </div>
                    </div>
                    <div className="where-when-sapling-box-auto">
                      <div className="where-when-sapling-box-img"></div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number">Key objective</p>
                        <p className="sapling-box-title">
                          {locationData[0] && locationData[0].keyobjectivies}
                        </p>
                      </div>
                    </div>
                    <div className="where-when-sapling-box">
                      <div className="where-when-sapling-box-img"></div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number">Species</p>
                        <p className="sapling-box-title">
                          {locationData[0] && locationData[0].species}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {!isMobile &&
                locationData[0] &&
                locationData[0].map &&
                locationData[0].map !== "" && (
                  <h5>
                    <a
                      href={locationData[0] && locationData[0].map}
                      target="_blank"
                    >
                      View on Map
                    </a>
                  </h5>
                )}
              {!isMobile && locationData?.[0]?.videoLink && (
                <div
                  onClick={() => setShowVid(true)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img data-src={youtube} className="lazyload" alt="youtube" />
                </div>
              )}
            </div>

            <Modal
              show={showVid}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={closeVideo}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="video">
                  <iframe
                    src={locationData?.[0]?.videoLink}
                    title=""
                    description=""
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    width="100%"
                    height="410px"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  />
                </div>
              </Modal.Body>
            </Modal>

            <div className="col-md-6">
              <div className="aco-tab-content-section">
                <div>
                  <ProjectTabSection3
                    // stateCarousel={stateCarousel}
                    // mapCurrentTab={mapCurrentTab}
                    onSelectLocation={onSelectLocation}
                  />
                </div>
              </div>
            </div>
          </div>
          {!isMobile && locationDataImage && locationDataImage.length !== 0 && (
            <SimpleReactLightbox>
              <section id="recentplantation">
                {/*   <HccbRecentPlantations images={locationData[0].images} />*/}

                <div
                  className="recent-plantation-section bosch-recent-plant hyd-recent-plant"
                  style={{ padding: "10px 0px" }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="section-title">
                          <h3
                            className="title-theme-heading"
                            style={{ textAlign: "left" }}
                          >
                            {" "}
                            Event Photography{" "}
                          </h3>
                        </div>
                        <div className="section pad-photography">
                          <div className="carousel-section">
                            <SRLWrapper>
                              <Carousel
                                arrows={false}
                                autoPlaySpeed={3000}
                                draggable
                                infinite
                                keyBoardControl
                                minimumTouchDrag={80}
                                partialVisible
                                renderButtonGroupOutside={true}
                                swipeable
                                responsive={responsive}
                                customButtonGroup={<ButtonGroup />}
                              >
                                {locationDataImage.map((item) => {
                                  return (
                                    <div className="img-wrapper" key={item.id}>
                                      <img
                                        data-src={item.img}
                                        className="lazyload"
                                        alt={item.alt}
                                      />
                                      <div className="overlay">
                                        <i className="icon icon-arrows-fullscreen"></i>
                                      </div>
                                    </div>
                                  );
                                })}
                              </Carousel>
                            </SRLWrapper>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </SimpleReactLightbox>
          )}
        </div>
      </div>
      <Modal show={mobileLocation} centered onHide={closeButton}>
        <div>
          <Modal.Header closeButton>
            <Modal.Title>
              {" "}
              <h4 className="when-town">
                {" "}
                {locationData[0] && locationData[0].plantationsite}{" "}
              </h4>
              <h5 className="when-city">
                {" "}
                {locationData[0] && locationData[0].state}
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="object-where-when-sapling">
                    <div className="where-when-sapling-box mobile-width">
                      <div className="where-when-sapling-box-img">
                        <img
                          data-src={saplingtree}
                          className="lazyload"
                          alt="saplingtree"
                        />
                      </div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number">
                          {locationData[0] && locationData[0].saplings}
                        </p>
                        <p className="sapling-box-title">Saplings</p>
                      </div>
                    </div>
                    <div className="where-when-sapling-box mobile-width">
                      <div className="where-when-sapling-box-img">
                        <img
                          data-src={econmicforest}
                          className="lazyload"
                          alt="econmicforest"
                        />
                      </div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number">
                          {locationData[0] && locationData[0].plantationstyle}
                        </p>
                        <p className="sapling-box-title">Plantation Style</p>
                      </div>
                    </div>

                    <div className="where-when-sapling-box mobile-width">
                      <div className="where-when-sapling-box-img">
                        <img
                          data-src={acresicon}
                          className="lazyload"
                          alt="acresicon"
                        />
                      </div>
                      <div className="where-when-sapling-box-content">
                        <p
                          className="sapling-box-number"
                          dangerouslySetInnerHTML={{
                            __html: locationData[0] && locationData[0].area,
                          }}
                        />
                        {/* <p className="sapling-box-number">
                          {locationData[0] && locationData[0].area}
                        </p>
                       <p className="sapling-box-title">Acres</p> */}
                      </div>
                    </div>
                    <div className="where-when-sapling-box mobile-width">
                      <div className="where-when-sapling-box-img">
                        <img
                          data-src={upcomingicon}
                          className="lazyload"
                          alt="upcomingicon"
                        />
                      </div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number green">
                          {locationData[0] && locationData[0].projectstatus}
                        </p>
                        <p className="sapling-box-title">Project Status</p>
                      </div>
                    </div>
                    <div className="where-when-sapling-box mobile-width">
                      <div className="where-when-sapling-box-img">
                        <img
                          data-src={eventdate}
                          className="lazyload"
                          alt="eventdate"
                        />
                      </div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number">
                          {locationData[0] && locationData[0].employeeevent}
                        </p>
                        <p className="sapling-box-title">
                          Plantation Event Date
                        </p>
                      </div>
                    </div>
                    <div className="where-when-sapling-box mobile-width">
                      <div className="where-when-sapling-box-img">
                        <img
                          data-src={eventdate}
                          className="lazyload"
                          alt="eventdate"
                        />
                      </div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number">
                          {locationData[0] && locationData[0].plantationperiod}
                        </p>
                        <p className="sapling-box-title">Plantation period </p>
                      </div>
                    </div>
                    <div className="where-when-sapling-box-auto mobile-width">
                      <div className="where-when-sapling-box-img"></div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number">Key objective</p>
                        <p className="sapling-box-title">
                          {locationData[0] && locationData[0].keyobjectivies}
                        </p>
                      </div>
                    </div>
                    <div className="where-when-sapling-box mobile-width">
                      <div className="where-when-sapling-box-img"></div>
                      <div className="where-when-sapling-box-content">
                        <p className="sapling-box-number">Species</p>
                        <p className="sapling-box-title">
                          {locationData[0] && locationData[0].species}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {locationData[0] &&
                locationData[0].map &&
                locationData[0].map !== "" && (
                  <h5>
                    <a
                      href={locationData[0] && locationData[0].map}
                      target="_blank"
                    >
                      View on Map
                    </a>
                  </h5>
                )}
              {locationData[0] &&
                locationData[0].images &&
                locationData[0].images.length !== 0 && (
                  <SimpleReactLightbox>
                    <section id="recentplantation">
                      <HccbRecentPlantations images={locationData[0].images} />
                    </section>
                  </SimpleReactLightbox>
                )}
              {locationData?.[0]?.videoLink && (
                <div className="video" style={{ paddingTop: "20px" }}>
                  <iframe
                    src={locationData?.[0]?.videoLink}
                    title=""
                    description=""
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    width="100%"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                    allowFullScreen
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeButton}>
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
}
