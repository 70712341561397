export const hccbplantation = {
  tabName: "Plantation",
  carousel: [
    /*  {
      id: 1,
      state: "Andhra Pradesh",
      saplings: "10000",
      area: "15 Acres",
      plantationsite: "Chilakaluripeta",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Economic Forest",
      species: "Sandalwood, Rosewood, Agarwood, Mahogany, Bamboo",
      projectstatus: "Upcoming",
      keyobjectivies: "Create longterm economic returns to local communities",
      map: "https://goo.gl/maps/BscLBaSTJ22AcViC6",
      images: [],
    },*/
    {
      id: 1,
      state: "Andhra Pradesh",
      saplings: "10000",
      area: "11 Acres",
      plantationsite: "Kurnool - Kurnool Ashram",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Economic Forest",
      species: "Sandalwood, Rosewood, Agarwood, Mahogany, Bamboo",
      projectstatus: "Upcoming",
      keyobjectivies: "Create longterm economic returns to local communities",
      map: "https://goo.gl/maps/m1CLTDzBRrcMgbqM7",
      images: [],
    },
    {
      id: 3,
      state: "Andhra Pradesh",
      saplings: "5000",
      area: "18 Acres",
      plantationsite: "Vizag - Vishakhapatnam Ashram",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Economic Forest",
      species: "Sandalwood, Rosewood, Agarwood, Mahogany, Bamboo",
      projectstatus: "Upcoming",
      keyobjectivies: "Create longterm economic returns to local communities",
      map: "https://goo.gl/maps/YvN44ABRZqfUasBeA",
      images: [],
    },
    {
      id: 4,
      state: "Andhra Pradesh",
      saplings: "10000",
      area: "5 Acres",
      plantationsite: "Tirupathi - Tirupati Ashram",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Economic Forest",
      species: "Sandalwood, Rosewood, Agarwood, Mahogany, Bamboo",
      projectstatus: "Upcoming",
      keyobjectivies: "Create longterm economic returns to local communities",
      map: "https://goo.gl/maps/YkA2a9ApU3eBzwP86",
      images: [],
    },
    {
      id: 5,
      state: "Telangana",
      saplings: "90000",
      area: "Institutional Land: 6.4 Acres <br>Madhur road: 75 Acres<br>Moringa field: 6.11 Acres",
      plantationsite: "Kanha Green Zone - Kanha Village",
      plantationperiod: "Oct 2022- March 2023",
      employeeevent: "10-11-2022",
      plantationstyle: "Biodiversity and conservation projects",
      species: "Bamboo, Mahogany, Sandal wood, Casuarina",
      projectstatus: "Ongoing",
      keyobjectivies:
        "These sites are located near Kanha Shanti Vanam and is home to several Floriculture and Horticulture farms. The land is quite fertile, although it is largely devoid of vegetation. We intend to create new biodiversity zone with primary objective of creating sanctuary for local wildlife (Bees, Butterflies, Squirrels’ and small wild animals). Greening this area will also benefit the local village communities and it creates a micro-climate of its own and contributes towards raising water table in the vicinity.",
      map: "https://goo.gl/maps/xic6JQYybi8wpsyq5",
      images: [
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kanhagreenzone/drone_image1.jpg",
          alt: "Kanha Green Zone - Kanha Village",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kanhagreenzone/drone_image2.jpeg",
          alt: "Kanha Green Zone - Kanha Village",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kanhagreenzone/drone_image3.jpeg",
          alt: "Kanha Green Zone - Kanha Village",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kanhagreenzone/image1.jpg",
          alt: "Kanha Green Zone - Kanha Village",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kanhagreenzone/image2.jpg",
          alt: "Kanha Green Zone - Kanha Village",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kanhagreenzone/image3.jpg",
          alt: "Kanha Green Zone - Kanha Village",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kanhagreenzone/image4.jpeg",
          alt: "Kanha Green Zone - Kanha Village",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kanhagreenzone/image5.jpeg",
          alt: "Kanha Green Zone - Kanha Village",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kanhagreenzone/image6.jpeg",
          alt: "Kanha Green Zone - Kanha Village",
        },
        {
          id: 2,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/moringafield/4223.jpg",
          alt: "Moringa Field - Telangana",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/madhurroad/10352.jpg",
          alt: "Madhur Road - Telangana",
        },
        {
          id: 2,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/madhurroad/10356.jpg",
          alt: "Madhur Road - Telangana",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/institutionalland/3998.jpg",
          alt: "Institutional Land - Telangana",
        },
        {
          id: 2,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/institutionalland/4214.jpg",
          alt: "Institutional Land - Telangana",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/images/20221110_ADV_9066.jpg",
          alt: "Kanha Village - Telangana",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/images/20221110_ADV_9204.jpg",
          alt: "Kanha Village - Telangana",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/images/20221110_ADV_9210.jpg",
          alt: "Kanha Village - Telangana",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/images/20221110_ADV_9256.jpg",
          alt: "Kanha Village - Telangana",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/images/20221110_ADV_9279.jpg",
          alt: "Kanha Village - Telangana",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/images/20221110_ADV_9312.jpg",
          alt: "Kanha Village - Telangana",
        },
      ],
      videoLink: "https://www.youtube.com/embed/h-TYKvSVpnM",
    },
    {
      id: 6,
      state: "Telangana",
      saplings: "10000",
      area: "8 Acres",
      plantationsite: "Jaipur - Jaipur Ashram",
      plantationperiod: "Nov 2022- March 2023",
      employeeevent: "30-11-2022",
      plantationstyle: "Economic Forest",
      species: "Sandalwood, Rosewood, Agarwood, Mahogany, Bamboo",
      projectstatus: "Ongoing",
      keyobjectivies:
        "To increase the biodiversity, To attract flora and Fauna.",
      map: "https://goo.gl/maps/h4z6UEpRk67ooCDy9",
      images: [
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/jaipur/image1.jpg",
          alt: "Jaipur - Jaipur Ashram",
        },
        {
          id: 2,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/jaipur/image2.jpg",
          alt: "Jaipur - Jaipur Ashram",
        },
        {
          id: 3,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/jaipur/image3.jpg",
          alt: "Jaipur - Jaipur Ashram",
        },
        {
          id: 4,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/jaipur/image4.jpg",
          alt: "Jaipur - Jaipur Ashram",
        },
        {
          id: 5,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/jaipur/image5.jpg",
          alt: "Jaipur - Jaipur Ashram",
        },
        {
          id: 6,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/jaipur/image6.jpg",
          alt: "Jaipur - Jaipur Ashram",
        },
      ],
    },
    {
      id: 7,
      state: "Karnataka",
      saplings: "10000",
      area: "11 Acres",
      plantationsite: "Kolar - Kolar Ashram ",
      plantationperiod: "Nov 2022- March 2023",
      employeeevent: "30-12-2022",
      plantationstyle: "Economic Forest",
      species: "Bamboo, Sandalwood, Casuarina",
      projectstatus: "Ongoing",
      keyobjectivies:
        "This site covers an area of 11.1 acres and the plantation drive is targeted towards increasing the biodiversity of the region and providing a sanctuary to the local fauna displaced by activities of mining in the region. This plantation will significantly benefit the local communities as the tree species being chosen are fruit bearing like Custard apple, Jamun, Amla and Manila tamarind. Alongside plantation, the team is working on increasing the capacity of the two onsite water ponds, to harvest rain water and to irrigate the entire plantation. We plan to plant around 10,000 trees on this site.",
      map: "https://goo.gl/maps/5cLZA3mighHLEzpb7",
      images: [
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kolar/image1.jpg",
          alt: "Kolar - Kolar Ashram",
        },
        {
          id: 2,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kolar/image2.jpg",
          alt: "Kolar - Kolar Ashram",
        },
        {
          id: 3,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kolar/image3.jpg",
          alt: "Kolar - Kolar Ashram",
        },
        {
          id: 4,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kolar/image4.jpg",
          alt: "Kolar - Kolar Ashram",
        },
        {
          id: 5,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kolar/image5.jpg",
          alt: "Kolar - Kolar Ashram",
        },
        {
          id: 6,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/kolar/image6.jpg",
          alt: "Kolar - Kolar Ashram",
        },
      ],
    },
    {
      id: 8,
      state: "Karnataka",
      saplings: "5000",
      area: "1 Acres",
      plantationsite: "Bidadi - CocaCola Site Bidadi",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Waterbody Plantation",
      species: "Arjuna, Jamun",
      projectstatus: "Upcoming",
      keyobjectivies: "Create biodiverse green zone along water body",
      map: "",
      images: [],
    },
    {
      id: 9,
      state: "Madhya Pradesh",
      saplings: "6000",
      area: "30 Acres",
      plantationsite: "Jaora Police Academy",
      plantationperiod: "Oct 2022 - March 2023",
      employeeevent: "30-12-2022",
      plantationstyle: "Hill Restoration",
      species: "Bamboo, Arjuna, Sandalwood",
      projectstatus: "Completed",
      keyobjectivies:
        "Challenging project on 30 Acres (Police Battalion land), 50+ tons of activated biochar , Water sources being created right now,  100+ native species selected &amp; planted for improving biodiversity, Red Species planted: Santalum album (East Indian sandal wood), Pterocarpus santalinus (Red sanders)",
      map: "https://goo.gl/maps/DfUwy7vMNQyjCgfRA",
      images: [
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/joara/photo1.jpeg",
          alt: "Jaora Police Academy - Madhya Pradesh",
        },
        {
          id: 2,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/joara/photo2.jpeg",
          alt: "Jaora Police Academy - Madhya Pradesh",
        },
        {
          id: 3,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/joara/photo3.jpeg",
          alt: "Jaora Police Academy - Madhya Pradesh",
        },
        {
          id: 4,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/joara/photo4.jpeg",
          alt: "Jaora Police Academy - Madhya Pradesh",
        },
        {
          id: 5,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/joara/photo5.jpeg",
          alt: "Jaora Police Academy - Madhya Pradesh",
        },
        {
          id: 6,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/joara/photo6.jpeg",
          alt: "Jaora Police Academy - Madhya Pradesh",
        },
        {
          id: 7,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/joara/photo7.jpeg",
          alt: "Jaora Police Academy - Madhya Pradesh",
        },
        {
          id: 8,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/joara/photo8.jpeg",
          alt: "Jaora Police Academy - Madhya Pradesh",
        },
      ],
    },
    {
      id: 10,
      state: "Madhya Pradesh",
      saplings: "10000",
      area: "13 Acres",
      plantationsite: "Indore - Indore Ashram",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Economic Forest",
      species: "Sandalwood, Rosewood, Agarwood, Mahogany, Bamboo",
      projectstatus: "Upcoming",
      keyobjectivies: "Create biodiverse green zone along water body",
      map: "https://goo.gl/maps/AAmr7xPDG9FBXGV89",
      images: [],
    },
    {
      id: 11,
      state: "Madhya Pradesh",
      saplings: "10000",
      area: "3 Acres",
      plantationsite: "Bhopal - Bhopal Ashram",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Economic Forest",
      species: "Sandalwood, Rosewood, Agarwood, Mahogany, Bamboo",
      projectstatus: "Upcoming",
      keyobjectivies: "Create longterm economic returns to local communities",
      map: "",
      images: [],
    },
    {
      id: 12,
      state: "Maharastra",
      saplings: "20000",
      area: "20 Acres",
      plantationsite: "Nagpur - Yerla Ashram",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Economic Forest",
      species: "Bamboo, Arjuna, Sandalwood",
      projectstatus: "Upcoming",
      keyobjectivies: "Create longterm economic returns to local communities",
      map: "https://goo.gl/maps/FNnh5UJgr4eqqrv46",
      images: [],
    },
    {
      id: 13,
      state: "Maharastra",
      saplings: "30000",
      area: "90 Acres",
      plantationsite: "Mumbai - Karjat",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Border Plantation",
      species: "Bamboo",
      projectstatus: "Upcoming",
      keyobjectivies:
        "Create wind-break zone and a microclimate and prevent soil erosion",
      map: "https://goo.gl/maps/VFJrfeMgbzjChDVW6",
      images: [],
    },
    {
      id: 14,
      state: "Tamilnadu",
      saplings: "40000",
      area: "20 Acres",
      plantationsite: "Tiruppur - DJ Park Ashram",
      plantationperiod: "Oct 2022 - March 2023",
      employeeevent: "30-12-2022",
      plantationstyle: "Aromatic Forest",
      species: "Bamboo, Sandalwood, Casuarina ",
      projectstatus: "Ongoing",
      keyobjectivies:
        "This site is situated 50 kms away from Coimbatore airport. Spread across 106     acres this campus is covered with lush vegetation. The plantation will be supported by 2 large water sumps. The entire campus is secured and fenced. Forests by heartfulness with an intention to increase biodiversity of the region and also to promote endangered and native species of tree will be planting 40,000 more trees which will enrich the flora and Fauna of the entire locality.",
      map: "https://goo.gl/maps/aMyxedayBJLAQCjXA",
      images: [
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/tiruppur/image1.jpeg",
          alt: "Tiruppur - Tamilnadu",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/tiruppur/image2.jpeg",
          alt: "Tiruppur - Tamilnadu",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/tiruppur/image3.jpeg",
          alt: "Tiruppur - Tamilnadu",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/tiruppur/image4.jpeg",
          alt: "Tiruppur - Tamilnadu",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/tiruppur/image5.jpeg",
          alt: "Tiruppur - Tamilnadu",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/tiruppur/image6.jpeg",
          alt: "Tiruppur - Tamilnadu",
        },
        {
          id: 1,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/tiruppur/photo1.jpg",
          alt: "Tiruppur - Tamilnadu",
        },
        {
          id: 2,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/tiruppur/photo2.jpg",
          alt: "Tiruppur - Tamilnadu",
        },
        {
          id: 3,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/tiruppur/photo3.jpg",
          alt: "Tiruppur - Tamilnadu",
        },
        {
          id: 4,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/tiruppur/photo4.jpg",
          alt: "Tiruppur - Tamilnadu",
        },
        {
          id: 5,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/tiruppur/photo5.jpg",
          alt: "Tiruppur - Tamilnadu",
        },
        {
          id: 6,
          img: "https://cdn-prod.heartfulness.org/FBH/cocacola/tiruppur/photo6.jpg",
          alt: "Tiruppur - Tamilnadu",
        },
      ],
    },
    {
      id: 15,
      state: "Gujarat",
      saplings: "5000",
      area: "2 Acres",
      plantationsite: "Ahmedabad - Sanand Industrial Area",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Border Plantation",
      species: "Bamboo ",
      projectstatus: "Upcoming",
      keyobjectivies:
        "Create wind-break zone and a microclimate and prevent soil erosion",
      map: "",
      images: [],
    },
    {
      id: 16,
      state: "Gujarat",
      saplings: "20000",
      area: "90 Acres",
      plantationsite: "Vadodara - Vadodara Ashram",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Border Plantation",
      species: "Bamboo ",
      projectstatus: "Upcoming",
      keyobjectivies:
        "Create wind-break zone and a microclimate and prevent soil erosion",
      map: "https://goo.gl/maps/vWMEUb5mf3EjiD559",
      images: [],
    },
    {
      id: 17,
      state: "West Bengal",
      saplings: "5000",
      area: "2 Acres",
      plantationsite: "Siliguri",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Border Plantation",
      species: "Bamboo ",
      projectstatus: "Upcoming",
      keyobjectivies:
        "Create wind-break zone and a microclimate and prevent soil erosion",
      map: "",
      images: [],
    },
    {
      id: 18,
      state: "Assam",
      saplings: "2000",
      area: "1 Acres",
      plantationsite: "Guwahati",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Economic Forest",
      species: "Sandalwood, Rosewood, Agarwood, Mahogany, Bamboo",
      projectstatus: "Upcoming",
      keyobjectivies: "Create longterm economic returns to local communities",
      map: "",
      images: [],
    },
    {
      id: 19,
      state: "Karnataka",
      saplings: "10000",
      area: "4 Acres",
      plantationsite: "Gulbarga - Gulbarga Ashram",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Economic Forest",
      species: "Bamboo",
      projectstatus: "Upcoming",
      keyobjectivies: "Mostly bamboo border plantation",
      map: "",
      images: [],
    },
    {
      id: 20,
      state: "Andhra Pradesh",
      saplings: "TBD",
      area: "TBD",
      plantationsite: "Nellore - Nellore ashram",
      plantationperiod: "July 2023 to Sep 2023",
      employeeevent: "TBD",
      plantationstyle: "Economic Forest",
      species: "TBD",
      projectstatus: "Upcoming",
      keyobjectivies: "TBD",
      map: "",
      images: [],
    },
  ],
  location: [
    /* {
      id: 1,
      name: "Chilakaluripeta - Andhra Pradesh",
      x: 36,
      y: 68,
    },*/
    {
      id: 1,
      name: "Kurnool - Andhra Pradesh",
      x: 34,
      y: 71,
    },
    {
      id: 3,
      name: "Vizag - Andhra Pradesh",
      x: 51,
      y: 62,
    },
    {
      id: 4,
      name: "Tirupathi - Andhra Pradesh",
      x: 38,
      y: 77,
    },
    {
      id: 5,
      name: "Kanha Green Zone - Telangana",
      x: 34,
      y: 66,
    },
    {
      id: 6,
      name: "Jaipur - Telangana",
      x: 38,
      y: 61,
    },
    {
      id: 7,
      name: "Kolar - Karnataka",
      x: 30,
      y: 79,
    },
    {
      id: 8,
      name: "Bidadi - Karnataka",
      x: 26,
      y: 79,
    },
    {
      id: 9,
      name: "Jaora Police Academy - Madhya Pradesh",
      x: 22,
      y: 43,
    },
    {
      id: 10,
      name: "Indore - Madhya Pradesh",
      x: 26,
      y: 46,
    },
    {
      id: 11,
      name: "Bhopal - Madhya Pradesh",
      x: 30,
      y: 44,
    },
    {
      id: 12,
      name: "Nagpur - Yerla - Maharastra",
      x: 34,
      y: 53,
    },
    {
      id: 13,
      name: "Mumbai - Karjat - Maharastra",
      x: 13.5,
      y: 56,
    },
    {
      id: 14,
      name: "Tiruppur - Tamilnadu",
      x: 30,
      y: 86,
    },
    {
      id: 15,
      name: "Ahmedabad - Gujarat",
      x: 12,
      y: 44,
    },
    {
      id: 16,
      name: "Vadodara - Gujarat",
      x: 14.5,
      y: 47,
    },
    {
      id: 17,
      name: "Siliguri - West Bengal",
      x: 67.5,
      y: 32,
    },
    {
      id: 18,
      name: "Guwahati - Assam",
      x: 75,
      y: 33,
    },
    {
      id: 19,
      name: "Gulbarga - Karnataka",
      x: 27,
      y: 66,
    },
    {
      id: 20,
      name: "Nellore - Andhra Pradesh",
      x: 39,
      y: 72,
    },
  ],
};
