import React from "react";
import hccbbannertree from "assets/images/hccb/hccb-banner-tree.png";

export default function Hccbbanner() {
  return (
    <div className="hccb-banner pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <h3 className="hccb-heading">
              {" "}
              Why HCCBs initiative to plant{" "}
              <span className="dis-block">
                {" "}
                <span className="red-title-clr"> 0.3 million trees </span> by
                2025?{" "}
              </span>{" "}
            </h3>

            <p className="hccb-p-text">
              {" "}
              The last 25 years have been years of tremendous change in India,
              and not only has HCCB successfully navigated these changes, but
              also enabled an ecosystem that is today solid, sustainable, and
              self-reliant. As we celebrate our 25th anniversary in 2022, we
              look towards the future and realize the need to go the extra mile
              and ensure that our sustainability strategy remains current,
              coordinated, and aligned for maximum effectiveness. So, when we
              were deliberating the various ways of celebrating our 25th
              anniversary, our employees proposed that we create a carbon sink
              and draw an ambitious plan to top up the 54% green energy usage
              that we already leverage. We thus gave ourselves challenge to
              plant more than 0.3 million!
            </p>
          </div>
          <div className="col-lg-6 col-md-6">
            <img
              className="img-fluid tree-logo-custom"
              src={hccbbannertree}
              alt="bannar tree"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
