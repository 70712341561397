import React from "react";

import uniquepic from "assets/images/hccb/unique-pic.png";

export default function Uniqueindustry() {
  return (
    <div className="project-execute hccb_inner-pages unique-industy col-lg-12">
      <div className="container">
        <div className="row mobi-column-rever">
          <div className="col-md-6 order-2">
            <h3 className="hccb-heading">
              {" "}
              Why is initiative Unique in the industry?{" "}
            </h3>
            <p className="hccb-p-text-industry">
              Creation of green belts is imperative to the ecological balance of
              the states that we operate in. This initiative by HCCB is not only
              an exercise in tree plantation but also a troika of carbon sink,
              community welfare and ecological benefit. The initiative goes
              beyond the idea of just maintaining our social license, but a step
              towards ESG+ that is enhancing our sustainability efforts.
            </p>
          </div>
          <div className="col-md-6 order-1">
            <img className="" src={uniquepic} alt="vadora ashramam" />
          </div>
        </div>
      </div>
    </div>
  );
}
