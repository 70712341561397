import React from "react";

import Hccblayout from "../hccblayout/index";

import SEO from "components/seo/index";

import Hccbbanner from "components/hccb/banner";

import Initiativecontent from "components/hccb/inititativecontent";

import Uniqueindustry from "components/hccb/unique-industry";

import Programexecute from "components/hccb/program-execute";

import SimpleReactLightbox from "simple-react-lightbox";

import RecentPlantations from "components/bosch/recentplantation";

import Counterbox from "components/hccb/counterbox";

import Wherewhen from "components/hccb/where-when-map";

const Hccbplantation = () => {
  return (
    <>
      <SEO title="BGSW Forest Plantation Drive" />
      <Hccblayout>
        <section id="hccb-banner">
          <Hccbbanner />
        </section>
        <section id="initiative-bg">
          <Initiativecontent />
        </section>
        <section id="where-when">
          <Wherewhen />
        </section>
        <section id="projectsunique">
          <Uniqueindustry />
        </section>
        <section id="project-execute">
          <Programexecute />
        </section>

        <section id="counterbox">
          <Counterbox />
        </section>
      </Hccblayout>
    </>
  );
};

export default Hccbplantation;
