import React from "react";
export default function Counterbox() {
  return (
    <div className="counter-box pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="counter-trees-items">
              <h3> 19</h3>
              <p> Plantation Sites</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="counter-trees-items">
              <h3> 3,00,000 </h3>
              <p>
                {" "}
                Targeted Trees <br /> 2022-23{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="counter-trees-items">
              <h3> 1,49,500 </h3>
              <p> Planted trees so far </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="counter-trees-items">
              <h3>120Acres </h3>
              <p> Area under plantation </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
