import React from "react";

import indiaMap from "assets/images/icons/indiamap.svg";

import leaf from "assets/images/hccb/location-red.svg";

// data

import { hccbplantation } from "assets/data/hccbmap";

const ProjectTabSection3 = ({ mapCurrentTab, onSelectLocation }) => {
  const changeMapSlider = (ev) => {
    // const currentSlide = parseInt(ev.currentTarget.id) + 1;
    // stateCarousel.current.goToSlide(currentSlide);
    onSelectLocation(ev);
  };

  return (
    <div>
      <div className="tab-wrapper tab-carousel">
        <div className="state-locator hccb-locator">
          <div className="sl-img-wrapper">
            <img data-src={indiaMap} className="lazyload" alt="indiaMap" />
            <div className="location-indicators">
              {hccbplantation.location.map((item) => {
                return (
                  <div
                    className={`li-img ${
                      item.id === mapCurrentTab ? "active" : ""
                    }`}
                    style={{
                      left: `${item.x}%`,
                      top: `${item.y}%`,
                    }}
                    key={item.id}
                    title={item.name}
                    id={item.id}
                    onClick={() => {
                      changeMapSlider(item);
                    }}
                  >
                    <img src={leaf} alt="leaf" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectTabSection3;
