import attractbird from "assets/images/hccb/attract-birds.png";
import providenature from "assets/images/hccb/provide-natureally.png";
import microclimate from "assets/images/hccb/micro-climate.png";
import raiselocal from "assets/images/hccb/raise-local.png";
import sequester from "assets/images/hccb/sequester.png";

export const hccbinitaiaivecontentdata = [
  {
    id: 1,
    img: attractbird,
    alt: "attractbird",
    title:
      " <span> Attract significant biodiversity </span> (birds, bees, rodents &amp; reptiles)",
  },

  {
    id: 2,
    img: providenature,
    alt: "nature",
    title:
      "<span>Provide naturally grown  fruit and medicinal herbs to the local communities</span>",
  },
  {
    id: 3,
    img: microclimate,
    alt: "grownfruit",
    title: "<span>Create a microclimate and abating city pollution</span>",
  },
  {
    id: 4,
    img: raiselocal,
    alt: "grownfruit",
    title: "<span>Raise local groundwater table</span>",
  },
  {
    id: 5,
    img: sequester,
    alt: "grownfruit",
    title:
      "<span>Sequester at least 5,000 tons of Carbon over next 10 years</span>",
  },
];
